import React from "react"
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions"
import { isMobileOnly } from "react-device-detect"

import Copyrights from "../components/Copyrights"
import NavBar from "../components/NavBar"
import NextPage from "../components/NextPage"

const LayoutCSS = ({ location, children }) => {
  return (
    <TransitionProvider
      location={location}
      style={{ overflow: "hidden" }}
      enter={{
        opacity: 0,
        transform: "translate3d(0,20vh,0) scale3d(1, 1, 1) rotate(0deg)",
        config: {
          mass: 1,
          tension: 210,
          friction: 20,
          clamp: true,
        },
      }}
      usual={{
        opacity: 1,
        transform: "translate3d(0vh,0vh,0) scale3d(1, 1, 1) rotate(0deg)",
      }}
      leave={{
        opacity: 0,
        transform: "translate3d(0vh,0vh,0) scale3d(2, 2, 1) rotate(180deg)",
        config: {
          duration: 1000,
        },
      }}
    >
      <NavBar />
      {!isMobileOnly && <NextPage direction="back" />}
      {!isMobileOnly && <NextPage direction="next" />}
      <TransitionViews>{children}</TransitionViews>
      <Copyrights />
    </TransitionProvider>
  )
}

export default LayoutCSS
