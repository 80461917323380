import React from "react"
import { Link } from "gatsby"
import { Animated } from "react-animated-css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import routes from "../routes"
import { Location } from "@reach/router"

const NextPage = ({ location, direction }) => {
  const currentPage = routes.findIndex(
    r => r.route === location.pathname || r.route + "/" === location.pathname
  )
  const nextRoute =
    direction !== "next" ? routes[currentPage + 1] : routes[currentPage - 1]

  console.log(
    "nextRoute:",
    direction,
    direction !== "next",
    nextRoute,
    currentPage
  )

  if (!nextRoute) return null

  return (
    <Link
      to={nextRoute.route}
      style={{
        position: "fixed",
        bottom: "20vh",
        right: direction === "next" ? undefined : "3vw",
        left: direction === "back" ? undefined : "3vw",
        textAlign: "center",
        color: "white",
        textDecoration: "none",
        zIndex: 9999,
        padding: 16,
        background: "rgba(0,0,0,0.2)",
        borderRadius: 16,
      }}
    >
      <Animated
        animationIn="bounce"
        style={{ animationDuration: "1s", animationIterationCount: "infinite" }}
      >
        <FontAwesomeIcon
          icon={direction === "next" ? "chevron-left" : "chevron-right"}
        />
      </Animated>
      {nextRoute.name}
    </Link>
  )
}

const NextPageLocated = ({ direction }) => (
  <Location>
    {({ location }) => <NextPage location={location} direction={direction} />}
  </Location>
)

export default NextPageLocated
