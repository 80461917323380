import withStyles from "@material-ui/styles/withStyles/withStyles"
import React from "react"
import { Link } from "gatsby"

const NavButton = withStyles(theme => ({
  navItemsContainer: {
    margin: "1rem",
    marginLeft: "4rem",
    color: "white",
    fontSize: "1.5rem",
    transition: `all .3s ease, width 1s ease`,
  },

  navItem: {
    whiteSpace: "nowrap",
    backgroundColor: "rgba(0,0,0,0.9)",
    padding: ".5rem 1rem",
    borderRadius: "3px",
    transition: `width .5s ease`,
    width: "fit-content",

    "&:hover": {
      color: "#04a6fc",
      fontWeight: "bold",
    },
  },
}))(
  class NavButtonUnstyled extends React.Component {
    state = {
      deployed: false,
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.openedNav !== nextProps.openedNav) {
        setTimeout(() => {
          this.setState({ deployed: nextProps.openedNav })
        }, 100 * this.props.order)
      }
    }

    render() {
      const {
        classes,
        text,
        path,
        onMouseEnter,
        onMouseLeave,
        onClick,
      } = this.props
      const { deployed } = this.state

      return (
        <Link
          to={path}
          style={{ textDecoration: "none", outline: "none" }}
          onClick={onClick}
        >
          <div
            className={classes.navItemsContainer}
            style={{
              opacity: deployed ? 1 : 0,
              transform: `scaleY(${deployed ? 1 : 0})`,
              height: `${deployed ? "3rem" : "0px"}`,
            }}
          >
            <div
              className={`${classes.navItem} paperize-4`}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {text}
            </div>
          </div>
        </Link>
      )
    }
  }
)

export default NavButton
