import React from "react"
import {Link} from "gatsby"

import withStyles from "@material-ui/styles/withStyles"

const Copyrights = ({classes}) => (
    <div className={classes.copyprights}>
        <Link to={"/legal"}>Mentions légales</Link>

        <div>&copy; 2017-2021 - Tech Ex Machina SAS</div>
    </div>
)

export default withStyles(() => ({
    copyprights: {
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        height: "25px",
        fontSize: "small",
        padding: "5px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "black",
        color: "whitesmoke",
        "& a": {
            color: "whitesmoke",
        },
    },
}))(Copyrights)
