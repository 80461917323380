const routes = [
  {
    id: "home",
    name: "Accueil",
    route: "/",
  },
  {
    id: "aboutUs",
    name: "À propos de nous",
    route: "/about-us",
  },
  {
    id: "process",
    name: "Notre métier",
    route: "/process",
  },
  {
    id: "contactUs",
    name: "Contactez nous",
    route: "/contact",
  },
]

export default routes
