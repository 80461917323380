import React from "react"

import routes from "../routes"
import NavButton from "./NavButton"
import MenuIcon from "../images/menu.png"
import makeStyles from "@material-ui/styles/makeStyles"
import colors from "../colors"

const useStyle = makeStyles({
  navbar: {
    zIndex: "1100",
    position: "fixed",
    top: "5px",
    left: "1rem",

    "@media (max-width:600px)": {
      top: 0,
      left: 0,
    },
  },

  buttonNav: {
    width: "42px",
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: colors.gris,
    backgroundColor: "rgba(255,255,255,0.1)",
    borderRadius: "50%",
  },

  cursor: {
    width: "2rem",
    height: "2rem",
    position: "absolute",
    left: "1rem",
    top: "4.1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .3s ease",
    opacity: 0,

    "&.show": {
      opacity: 1,
    },

    "& svg": {
      width: "2.2rem",
      height: "2.2rem",
    },
  },
})

const LateralNavBar = () => {
  const [opened, setOpened] = React.useState(false)
  const classes = useStyle()

  return (
    <div className={classes.navbar}>
      <div className={classes.buttonNav} onClick={() => setOpened(!opened)}>
        <img alt="Menu" src={MenuIcon} />
      </div>

      {routes.map(({ name, route }) => (
        <NavButton
          onClick={() => setOpened(false)}
          key={route}
          text={name}
          openedNav={opened}
          path={route}
        />
      ))}
    </div>
  )
}

export default LateralNavBar
